@import "variables";
// includes
@import "includes/functions";
@import "includes/normalize";
@import "includes/45p";
@import "includes/fonts";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
    font-size: 16px;
    line-height: 1.2;
    overflow-x: hidden;
    @media screen and (max-width: 1400px){
        font-size: 14px;
    }
}

body {
    background-color: $white;
    color: $blue;
    font-family: $primary-font;
    font-weight: normal;
    font-size: rem(16);
    line-height: 1.2;
    overflow: hidden;
}

a{
    color: inherit;
    @include hover{
        color: $primary-color;
    }
}

.container{
    max-width: rem(1230);
}

#header{
    padding: rem(30);
    background-color: $gray-l;
    position: relative;
    h1{
        width: 100%;
        max-width: rem(510);
        margin: 0 auto;
    }

    .after{
        position: absolute;
        content: '';
        left: 20%;
        top: rem(40);
        width: rem(38);
        height: rem(38);
        background-color: $orange;
        border-radius: 50%;
    }

    .before{
        position: absolute;
        content: '';
        right: 5%;
        bottom: rem(-55);
        width: rem(110);
        height: rem(110);
        background-color: $white;
        border: 1px solid $blue;
        border-radius: 50%;
        @media screen and (max-width: 540px){
            bottom: rem(-30);
            width: rem(60);
            height: rem(60);
        }
    }
}

#login{
    
    p{
        text-align: center;
        font-size: rem(18);
        line-height: 1.6;
        width: 100%;
        max-width: rem(770);
        margin: rem(50) auto rem(35);
        a{
            display: inline-block;
        }
    }

    .wrap{
        width: 100%;
        max-width: rem(630);
        margin: rem(30) auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 1px solid $gray;
        padding: rem(30) rem(15) rem(55);
        @media screen and (max-width: 540px){
            flex-direction: column;
        }
        img{
            width: rem(200);
        }

        span{
            font-size: rem(36);
            display: block;
            padding: rem(25);
            font-weight: bold;
        }
        a{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);
            background-color: $blue;
            color: $white;
            font-weight: bold;
            font-size: rem(14);
            text-transform: uppercase;
            letter-spacing: .2em;
            padding: rem(16) rem(30);
            border-radius: rem(25);
            min-width: rem(220);
            text-align: center;
            @include hover{
                background-color: $green;
            }
        }

        position: relative;
        .after{
            position: absolute;
            content: '';
            left: rem(-800);
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            width: rem(420);
            height: rem(420);
            background-color: $white;
            border: 1px solid $gray;
        }
    }
}

#products{
    padding: rem(100) 0 rem(200);
    h2{
        color: $green;
        font-weight: bold;
        text-align: center;
        font-size: rem(100);
        position: relative;
        z-index: 2;
        @media screen and (max-width: 991px){
            font-size: rem(60);
            margin-bottom: rem(30);
        }
    }
    .item{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(18);
        line-height: 1.5;
        .content{
            flex: 1;
        }

        .image{
            flex-basis: 60%;
        }

        &:nth-child(even){
            margin-top: rem(-100);
            @media screen and (max-width: 991px){
                margin-top: 0;
            }
            .image{
                @media screen and (min-width: 992px){
                    padding-right: rem(30);
                    margin-left: rem(-100);
                }
                position: relative;
                
                .after{
                    position: absolute;
                    content: '';
                    left: 12%;
                    bottom: rem(30);
                    width: rem(110);
                    height: rem(110);
                    background-color: $white;
                    border: 1px solid $blue;
                    border-radius: 50%;
                }
            }
        }
        &:nth-child(odd){
            flex-direction: row-reverse;
            margin-top: rem(140);
            .image{
                @media screen and (min-width: 992px){
                    padding-left: rem(30);
                    margin-right: rem(-100);
                }
                position: relative;
                @media screen and (max-width: 991px){
                    margin-top: 17%;
                }
                .after{
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: rem(30);
                    right: 0;
                    transform: translateY(-50%);
                    padding-bottom: calc(100% - 1.875rem);
                    background-color: $blue;
                    border-radius: 50%; 
                    
                    @media screen and (max-width: 991px){
                        padding-bottom: calc(100% - 3.75rem);
                        right: rem(30);
                    }
                }

                img{
                    position: relative;
                    z-index: 2;
                }

                @media screen and (max-width: 1260px){
                    margin-right: 0;
                }
            }
        }

        @media screen and (max-width: 991px){
            &:nth-child(odd), &:nth-child(even){
                flex-direction: column-reverse;
                
                .image{
                    padding: 0 rem(30);
                }
            }

        }
    }

    .heading{
        display: block;
        max-width: rem(450);
        padding-left: rem(30);
        border-left: rem(10) solid $orange;
        font-size: rem(29);
        margin-bottom: rem(55);
        h3{
            font-weight: bold;
            font-size: 1em;
        }
        p{
            margin: 0;
        }
    }

    .bold{
        font-weight: 700;
        font-style: italic;
        display: block;
    }
}

#footer{
    position: relative;
    .after{
        position: absolute;
        content: '';
        left: 50%;
        transform: translateX(-50%);
        top: rem(40);
        width: rem(38);
        height: rem(38);
        background-color: $orange;
        border-radius: 50%;
    }
    background-color: $gray-l;
    text-align: center;
    padding: rem(80) 0 rem(15);
    p{
        font-size: rem(24);
    }

    .btn{
        display: inline-block;
        background-color: $blue;
        color: $white;
        font-weight: bold;
        font-size: rem(14);
        text-transform: uppercase;
        letter-spacing: .2em;
        padding: rem(16) rem(50);
        border-radius: rem(25);
        @include hover{
            background-color: $green;
        }
    }

    .copyright{
        margin-top: rem(50);
        font-size: rem(10);
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .1em;
        a{
            display: inline-block;
        }
    }
}