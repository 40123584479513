//settings
$fa-font-path: "../font";

// Colors //
$black: #000;
$white: #fff;
$blue: #14325c;
$orange: #ff893e;
$green: #63714d;

$primary-color: #e2223d;
$gray-l: #f3f3f3;
$gray: #e8e8e8;

//Fonts
$primary-font: 'DIN', sans-serif;
